<template>
  <el-main>
    <page-title show-back-btn />
    <div v-loading="loading.detail" class="partition-area">
      <el-form label-width="80px" :label-suffix="constants.labelSuffix">
        <ics-page-inner title="电子账薄详情">
          <template slot="btn-inner">
            <div class="btn-inner">
              <el-popover
                placement="left"
                width="400"
                trigger="click"
              >
                <table-inner v-loading="loading.list" title="白名单列表" :table-data="gridData.list" :page-num="gridData.pageNum" :page-size="gridData.pageSize" :pages="gridData.pages" :page-id="gridData.pageId" @changePageNum="changeWhitePageNum">
                  <template slot="table-columns">
                    <el-table-column prop="bankAccountName" label="账户名称" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
                    <el-table-column prop="bankAccountNumber" label="账户号码" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
                  </template>
                </table-inner>
                <el-button slot="reference" type="primary" @click="getWhitePage">
                  账户白名单管理
                </el-button>
              </el-popover>
            </div>
          </template>
          <el-col :span="24">
            <div style="border: 1px solid #EBEEF5;padding: 0px;">
              <el-col :span="10" style="border: 1px solid #EBEEF5; height: 200px;">
                <div class="area">
                  <p class="title">
                    账户余额：
                  </p>
                  <p class="btn-inner">
                    <el-button type="primary" round @click="clickRecharge">
                      充值
                    </el-button>
                    <el-button type="warning" round @click="clickWithdrawal">
                      提现
                    </el-button>
                    <el-button type="warning" round @click="clickTransfer">
                      转账
                    </el-button>
                  </p>
                </div>
                <el-col :span="24">
                  <p style="font-size: 30px; font-family: 宋体; margin-left: 70px;margin-top: 20px;">
                    {{ filterMoney(accountDetail.balance) }} 元
                  </p>
                </el-col>
              </el-col>
              <el-col :span="7" style="border: 1px solid #EBEEF5; height: 200px;">
                <el-col :span="24" style="margin-top: 10px;">
                  <el-form-item label="在途余额" style="margin-left: -40px;">
                    <p>{{ filterMoney(accountDetail.receivableBalance) }} 元</p>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="冻结余额" style="margin-left: -40px;">
                    <p>{{ filterMoney(accountDetail.frozenAmount) }} 元</p>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="到账余额" style="margin-left: -40px;">
                    <p>{{ filterMoney(accountDetail.receivedBalance) }} 元</p>
                  </el-form-item>
                </el-col>
              </el-col>
              <el-col :span="7" style="border: 1px solid #EBEEF5; height: 200px;">
                <el-col :span="24" style="margin-top: 10px;">
                  <el-form-item label="ID" style="margin-left: -40px; width: 360px;">
                    <p>{{ utils.isEffectiveCommon(accountDetail.userID) }}</p>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="账户名称" style="margin-left: -40px; width: 360px;">
                    <p>{{ utils.isEffectiveCommon(accountDetail.userName) }}</p>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="账户类型" style="margin-left: -40px; width: 360px;">
                    <p>{{ utils.statusFormat(accountDetail.userType, 'userType') }}</p>
                  </el-form-item>
                </el-col>
              </el-col>
            </div>
          </el-col>
        </ics-page-inner>
        <search-inner title="" :search-id="1" :search-form="searchForm" @submit-search="search" @clear-search="reset">
          <template>
            <el-col :span="7">
              <el-form-item label="时间：">
                <ics-search-date :date.sync="searchForm.dateArr" :is-show-options="false" />
              </el-form-item>
            </el-col>
          </template>
        </search-inner>
        <table-inner v-loading="loading.list" title="账户明细" :table-data="tableList.itemList" :page-num="Number(tableList.currentPage)" :page-size="10" :pages="Number(tableList.totalPage)" :page-id="tableList.pageId" @changePageNum="changePageNum">
          <template slot="table-columns">
            <el-table-column type="index" label="序号" align="center" show-overflow-tooltip min-width="100" />
            <el-table-column prop="txSn" label="交易流水号" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
            <el-table-column prop="operation" label="类型" align="center" :formatter="(row, col, cell) => utils.statusFormat(cell, 'eJz4692OperationType')" show-overflow-tooltip min-width="150" />
            <el-table-column prop="amount" label="金额(元)" align="center" :formatter="(row, col, cell) => filterMoney(cell)" show-overflow-tooltip min-width="150" />
            <el-table-column prop="txTime" label="时间" align="center" :formatter="(row, col, cell) => stringToDate(cell)" show-overflow-tooltip min-width="150" />
            <el-table-column prop="remark" label="备注" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
          </template>
        </table-inner>
      </el-form>
    </div>
    <!-- 个人/个体 -->
    <ics-dialog-inner width="50%" :loading="loading.submit" :visible.sync="dialog.singleRecharge.visible" :title="dialog.singleRecharge.title" submit-title="提交" :show-cancel="false" class="form-dialog" @submit="submitSingleRechargeForm" @close="closeDialog">
      <ics-page-inner title="" :show-header="true">
        <el-form ref="singleRechargeForm" :model="singleRechargeForm" :rules="singleRechargeRules" label-width="120px" label-position="left" :label-suffix="constants.labelSuffix">
          <el-col :span="16" :push="4">
            <el-form-item label="充值方式">
              <el-radio-group v-model="singleRechargeForm.mode" @change="changeSingleRechargeMode">
                <el-radio label="13">
                  线上充值
                </el-radio>
                <el-radio label="16">
                  线下充值
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <div v-if="singleRechargeForm.mode === '13'">
            <el-col />
            <el-col :span="16" :push="4">
              <el-form-item label="充值金额(元)" prop="onLine.amount">
                <el-input v-model="singleRechargeForm.onLine.amount" type="number" placeholder="请输入充值金额" />
              </el-form-item>
            </el-col>
            <el-col />
            <el-col :span="24" :push="4">
              <el-form-item label="支付方式" prop="onLine.method">
                <el-radio-group v-model="singleRechargeForm.onLine.method">
                  <el-radio-button label="kj">
                    <i class="el-icon-kj" /><span>快捷支付</span>
                  </el-radio-button>
                  <el-radio-button label="wx">
                    <i class="el-icon-wx" /><span>微信支付</span>
                  </el-radio-button>
                  <el-radio-button label="zfb">
                    <i class="el-icon-zfb" /><span>支付宝支付</span>
                  </el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </div>
          <div v-if="singleRechargeForm.mode === '16'">
            <el-col />
            <el-col :span="16" :push="4">
              <el-form-item label="收款账户">
                <p>{{ utils.isEffectiveCommon(eaccountNumber) }}</p>
              </el-form-item>
            </el-col>
            <el-col />
            <el-col :span="16" :push="4">
              <el-form-item label="付款账户名称" prop="offline.bankAccountName">
                <el-input v-model="singleRechargeForm.offline.bankAccountName" placeholder="请输入付款账户名称" />
              </el-form-item>
            </el-col>
            <el-col />
            <el-col :span="16" :push="4">
              <el-form-item label="付款账户号码" prop="offline.bankAccountNumber">
                <el-input v-model="singleRechargeForm.offline.bankAccountNumber" placeholder="请输入付款账户号码" @input="(v) => (singleRechargeForm.offline.bankAccountNumber = v.replace(/\s/g, ''))" />
              </el-form-item>
            </el-col>
            <el-col :span="16" :push="4">
              <el-form-item>
                <p style="color: red;">
                  *&nbsp;非同名入金需将付款账号维护至白名单
                </p>
              </el-form-item>
            </el-col>
          </div>
        </el-form>
      </ics-page-inner>
    </ics-dialog-inner>
    <!-- 企业 -->
    <ics-dialog-inner width="45%" :loading="loading.submit" :visible.sync="dialog.recharge.visible" :title="dialog.recharge.title" submit-title="提交" :show-cancel="false" class="form-dialog" @submit="submitRechargeForm" @close="closeDialog">
      <ics-page-inner title="" :show-header="true">
        <el-form ref="rechargeForm" :model="rechargeForm" :rules="rechargeRules" label-width="120px" label-position="left" :label-suffix="constants.labelSuffix">
          <el-col :span="16" :push="4">
            <el-form-item label="充值方式">
              <el-radio-group v-model="rechargeForm.mode" @change="changeRechargeMode">
                <el-radio label="13">
                  线上充值
                </el-radio>
                <el-radio label="16">
                  线下充值
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <div v-if="rechargeForm.mode === '13'">
            <el-col />
            <el-col :span="16" :push="4">
              <el-form-item prop="onLine.amount" label="充值金额(元)">
                <el-input v-model="rechargeForm.onLine.amount" type="number" placeholder="请输入充值金额" />
              </el-form-item>
            </el-col>
            <el-col />
          </div>
          <div v-if="rechargeForm.mode === '16'">
            <el-col />
            <el-col :span="16" :push="4">
              <el-form-item label="收款账户">
                <p>{{ utils.isEffectiveCommon(eaccountNumber) }}</p>
              </el-form-item>
            </el-col>
            <el-col />
            <el-col :span="16" :push="4">
              <el-form-item label="付款账户名称" prop="offline.bankAccountName">
                <el-input v-model="rechargeForm.offline.bankAccountName" placeholder="请输入付款账户名称" />
              </el-form-item>
            </el-col>
            <el-col />
            <el-col :span="16" :push="4">
              <el-form-item label="付款账户号码" prop="offline.bankAccountNumber">
                <el-input v-model="rechargeForm.offline.bankAccountNumber" placeholder="请输入付款账户号码" @input="(v) => (rechargeForm.offline.bankAccountNumber = v.replace(/\s/g, ''))" />
              </el-form-item>
            </el-col>
            <el-col :span="16" :push="4">
              <el-form-item>
                <p style="color: red;">
                  *&nbsp;非同名入金需将付款账号维护至白名单
                </p>
              </el-form-item>
            </el-col>
          </div>
        </el-form>
      </ics-page-inner>
    </ics-dialog-inner>
    <ics-dialog-inner width="40%" :loading="loading.submit" :visible.sync="dialog.withdrawal.visible" :title="dialog.withdrawal.title" submit-title="提交" :show-cancel="false" class="form-dialog" @submit="submitWithdrawalForm" @close="closeDialog">
      <ics-page-inner title="" :show-header="true">
        <el-form ref="withdrawalForm" :model="withdrawalForm" :rules="withdrawalRules" label-width="140px" label-position="left" :label-suffix="constants.labelSuffix">
          <el-col :span="16" :push="3">
            <el-form-item label="提现金额(元)" prop="amount">
              <el-input v-model="withdrawalForm.amount" type="number" placeholder="请输入提现金额" />
            </el-form-item>
          </el-col>
        </el-form>
      </ics-page-inner>
    </ics-dialog-inner>
    <!-- 个人/个体 -->
    <ics-dialog-inner width="50%" :loading="loading.submit" :visible.sync="dialog.singleTransfer.visible" :title="dialog.transfer.title" submit-title="提交" :show-cancel="false" class="form-dialog" @submit="submitSingleTransferForm" @close="closeDialog">
      <ics-page-inner title="" :show-header="true">
        <el-form ref="singleTransferForm" :model="singleTransferForm" :rules="singleTransferRules" label-width="140px" label-position="left" :label-suffix="constants.labelSuffix">
          <el-col :span="16" :push="3">
            <el-form-item label="转账类型">
              <el-radio-group v-model="singleTransferForm.isChujin" @change="changeSingleTransferChujin">
                <el-radio label="0">
                  对内转账
                </el-radio>
                <el-radio label="1">
                  对外转账
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <div v-if="singleTransferForm.isChujin === '0'">
            <el-col :span="16" :push="3">
              <el-form-item label="收款金额(元)" prop="internally.amount">
                <el-input v-model="singleTransferForm.internally.amount" type="number" placeholder="请输入收款金额" />
              </el-form-item>
            </el-col>
            <el-col />
            <el-col :span="16" :push="3">
              <el-form-item label="收款账户号码" prop="internally.eAccountNumber">
                <el-input v-model="singleTransferForm.internally.eAccountNumber" placeholder="请输入收款账户号码" @input="(v) => (singleTransferForm.internally.eAccountNumber = v.replace(/\s/g, ''))" />
              </el-form-item>
            </el-col>
          </div>
          <div v-if="singleTransferForm.isChujin === '1'">
            <el-col :span="16" :push="3">
              <el-form-item>
                <p style="color: red;">
                  *&nbsp;对外转账，需先联系中金支付客户经理
                </p>
              </el-form-item>
            </el-col>
            <el-col :span="16" :push="3">
              <el-form-item label="转账金额(元)" prop="foreign.amount">
                <el-input v-model="singleTransferForm.foreign.amount" placeholder="请输入转账金额" />
              </el-form-item>
            </el-col>
            <el-col />
            <el-col :span="16" :push="3">
              <el-form-item label="收款账户类型" prop="foreign.bankAccountType">
                <el-select v-model="singleTransferForm.foreign.bankAccountType" placeholder="请选择收款账户类型" style="width: 100%">
                  <el-option v-for="item in constants.bankAccountType" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col />
            <el-col :span="16" :push="3">
              <el-form-item label="银行ID" prop="foreign.bankId">
                <el-select v-model="singleTransferForm.foreign.bankId" placeholder="请选择银行账户名称/ID" style="width: 100%" filterable>
                  <el-option v-for="item in zjBankCodeList" :key="item.code" :label="`${item.name}（${item.code}）`" :value="item.code" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col />
            <el-col :span="16" :push="3">
              <el-form-item label="收款账户号码" prop="foreign.payeeAccountNumber">
                <el-input v-model="singleTransferForm.foreign.payeeAccountNumber" placeholder="请输入收款账户号码" @input="(v) => (singleTransferForm.foreign.payeeAccountNumber = v.replace(/\s/g, ''))" />
              </el-form-item>
            </el-col>
            <el-col />
            <el-col :span="16" :push="3">
              <el-form-item label="收款账户名称" prop="foreign.payeeAccountName">
                <el-input v-model="singleTransferForm.foreign.payeeAccountName" placeholder="请输入收款账户名称" />
              </el-form-item>
            </el-col>
            <el-col />
            <el-col :span="16" :push="3">
              <el-form-item label="转账备注" prop="foreign.note">
                <el-input v-model="singleTransferForm.foreign.note" placeholder="请输入转账备注" type="textarea" :rows="4" />
              </el-form-item>
            </el-col>
          </div>
        </el-form>
      </ics-page-inner>
    </ics-dialog-inner>
    <!-- 企业 -->
    <ics-dialog-inner width="50%" :loading="loading.submit" :visible.sync="dialog.transfer.visible" :title="dialog.transfer.title" submit-title="提交" :show-cancel="false" class="form-dialog" @submit="submitTransferForm" @close="closeDialog">
      <ics-page-inner title="" :show-header="true">
        <el-form ref="transferForm" :model="transferForm" :rules="transferRules" label-width="140px" label-position="left" :label-suffix="constants.labelSuffix">
          <el-col :span="16" :push="3">
            <el-form-item label="转账类型">
              <el-radio-group v-model="transferForm.isChujin" @change="changeTransferChujin">
                <el-radio label="0">
                  对内转账
                </el-radio>
                <el-radio label="1">
                  对外转账
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <div v-if="transferForm.isChujin === '0'">
            <el-col :span="16" :push="3">
              <el-form-item label="收款金额(元)" prop="internally.amount">
                <el-input v-model="transferForm.internally.amount" type="number" placeholder="请输入收款金额" />
              </el-form-item>
            </el-col>
            <el-col />
            <el-col :span="16" :push="3">
              <el-form-item label="收款账户号码" prop="internally.eAccountNumber">
                <el-input v-model="transferForm.internally.eAccountNumber" placeholder="请输入收款账户号码" @input="(v) => (transferForm.internally.eAccountNumber = v.replace(/\s/g, ''))" />
              </el-form-item>
            </el-col>
          </div>
          <div v-if="transferForm.isChujin === '1'">
            <el-col :span="16" :push="3">
              <el-form-item>
                <p style="color: red;">
                  *&nbsp;对外转账，需先联系中金支付客户经理
                </p>
              </el-form-item>
            </el-col>
            <el-col :span="16" :push="3">
              <el-form-item label="转账金额(元)" prop="foreign.amount">
                <el-input v-model="transferForm.foreign.amount" type="number" placeholder="请输入转账金额" />
              </el-form-item>
            </el-col>
            <el-col />
            <el-col :span="16" :push="3">
              <el-form-item label="收款账户类型" prop="foreign.bankAccountType">
                <el-select v-model="transferForm.foreign.bankAccountType" placeholder="请选择收款账户类型" style="width: 100%">
                  <el-option v-for="item in constants.bankAccountType" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col />
            <el-col :span="16" :push="3">
              <el-form-item label="银行ID" prop="foreign.bankId">
                <el-select v-model="transferForm.foreign.bankId" placeholder="请选择银行账户名称/ID" style="width: 100%" filterable>
                  <el-option v-for="item in zjBankCodeList" :key="item.code" :label="`${item.name}（${item.code}）`" :value="item.code" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col />
            <el-col :span="16" :push="3">
              <el-form-item label="收款账户号码" prop="foreign.payeeAccountNumber">
                <el-input v-model="transferForm.foreign.payeeAccountNumber" placeholder="请输入收款账户号码" @input="(v) => (transferForm.foreign.payeeAccountNumber = v.replace(/\s/g, ''))" />
              </el-form-item>
            </el-col>
            <el-col />
            <el-col :span="16" :push="3">
              <el-form-item label="收款账户名称" prop="foreign.payeeAccountName">
                <el-input v-model="transferForm.foreign.payeeAccountName" placeholder="请输入收款账户名称" />
              </el-form-item>
            </el-col>
            <el-col />
            <el-col :span="16" :push="3">
              <el-form-item label="转账备注" prop="foreign.note">
                <el-input v-model="transferForm.foreign.note" placeholder="请输入转账备注" type="textarea" :rows="4" />
              </el-form-item>
            </el-col>
          </div>
        </el-form>
      </ics-page-inner>
    </ics-dialog-inner>
  </el-main>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
import IcsDialogInner from '@/components/dialog-inner'
import IcsSearchDate from '@/components/search-date'
import moment from 'moment'
export default {
  components: {IcsSearchDate, IcsDialogInner },
  mixins: [routeEnterMixin],
  data() {
    return {
      //userId: '9999999999999',
      userId: this.$route.query.userId,
      firmId: this.$route.query.firmId,
      eaccountNumber: this.$route.query.eaccountNumber,
      accountDetail: {},
      searchForm: {
        startDate: '',
        endDate: '',
        dateArr: [],
        pageIndex: 1,
        pageSize: 10
      },
      whiteSearchForm: {},
      gridData: [],
      zjBankCodeList: [],
      tableList: {
        itemList: [],
        currentPage: '0',
        totalPage: '0'
      },
      validateFlag: 0,
      singleRechargeForm: {
        mode: '13',
        offline: {
          bankAccountName: '',
          bankAccountNumber: ''
        },
        onLine: {
          amount: '',
          method: '',
          paymentWay: '',
          QRPaymentWay: ''
        }
      },
      rechargeForm: {
        mode: '13',
        offline: {
          bankAccountName: '',
          bankAccountNumber: ''
        },
        onLine: {
          paymentWay: '20',
          amount: ''
        }
      },
      withdrawalForm: {
        amount: ''
      },
      singleTransferForm: {
        isChujin: '0',
        internally: {
          amount: '',
          eAccountNumber: '',
        },
        foreign: {
          amount: '',
          bankAccountType: '',
          payeeAccountNumber: '',
          payeeAccountName: '',
          note: '',
          bankId: ''
        },
      },
      transferForm: {
        isChujin: '0',
        internally: {
          amount: '',
          eAccountNumber: '',
        },
        foreign: {
          amount: '',
          bankAccountType: '',
          payeeAccountNumber: '',
          payeeAccountName: '',
          note: '',
          bankId: ''
        },
      },
      dialog: {
        recharge: {
          visible: false,
          title: '充值'
        },
        singleRecharge: {
          visible: false,
          title: '充值'
        },
        withdrawal: {
          visible: false,
          title: '提现'
        },
        singleTransfer: {
          visible: false,
          title: '转账'
        },
        transfer: {
          visible: false,
          title: '转账'
        }
      },
      rules: {},
      rechargeRules: {
        onLine: {
          amount: [
            { required: false, message: '请输入充值金额', trigger: 'blur' }
          ]
        },
        offline: {
          bankAccountName: [
            { required: false, message: '请输入付款银行账户名称', trigger: 'blur' }
          ],
          bankAccountNumber: [
            { required: false, message: '请输入付款银行账户号码', trigger: 'blur' }
          ],
        }
      },
      singleRechargeRules: {
        onLine: {
          amount: [
            { required: false, message: '请输入充值金额', trigger: 'blur' }
          ],
          method: [
            { required: false, message: '请选择支付方式', trigger: 'change' }
          ]
        },
        offline: {
          bankAccountName: [
            { required: false, message: '请输入付款银行账户名称', trigger: 'blur' }
          ],
          bankAccountNumber: [
            { required: false, message: '请输入付款银行账户号码', trigger: 'blur' }
          ],
        }
      },
      withdrawalRules: {
        amount: [
          { required: true, message: '请输入提现金额', trigger: 'blur' }
        ]
      },
      singleTransferRules: {
        internally: {
          amount: [
            { required: false, message: '请输入收款金额', trigger: 'blur' }
          ],
          eAccountNumber: [
            { required: false, message: '请输入收款账户号码', trigger: 'blur' }
          ],
        },
        foreign: {
          amount: [
            { required: false, message: '请输入转账金额', trigger: 'blur' }
          ],
          bankAccountType: [
            { required: false, message: '请选择收款账户类型', trigger: 'change' }
          ],
          payeeAccountNumber: [
            { required: false, message: '请输入收款账户号码', trigger: 'blur' }
          ],
          payeeAccountName: [
            { required: false, message: '请输入收款账户名称', trigger: 'blur' }
          ],
          bankId: [
            { required: false, message: '请选择银行ID', trigger: 'change' }
          ],
          note: [
            { required: false, message: '请输入转账备注', trigger: 'blur' }
          ],
        },
      },
      transferRules: {
        internally: {
          amount: [
            { required: false, message: '请输入收款金额', trigger: 'blur' }
          ],
          eAccountNumber: [
            { required: false, message: '请输入收款账户号码', trigger: 'blur' }
          ],
        },
        foreign: {
          amount: [
            { required: false, message: '请输入转账金额', trigger: 'blur' }
          ],
          bankAccountType: [
            { required: false, message: '请选择收款账户类型', trigger: 'change' }
          ],
          payeeAccountNumber: [
            { required: false, message: '请输入收款账户号码', trigger: 'blur' }
          ],
          payeeAccountName: [
            { required: false, message: '请输入收款账户名称', trigger: 'blur' }
          ],
          bankId: [
            { required: false, message: '请选择银行ID', trigger: 'change' }
          ],
          note: [
            { required: false, message: '请输入转账备注', trigger: 'blur' }
          ],
        },
      },
    }
  },
  created() {
    if (this.userId) {
      this.eJz4691()
      this.recoverSearch()
    }
  },
  methods: {
    eJz4691 () {
      this.loading.detail = true
      this.api.system.account.eJz4691(this.userId).then(result => {
        const data = JSON.parse(result.data.data)
        this.accountDetail = data || {}
      }).finally(() => {
        this.loading.detail = false
      })
    },
    recoverSearch () {
      // 用于页面初始化时拉取列表数据
      this.getSearchForm()
    },
    getSearchForm () {
      this.searchForm.pageIndex = 1
      this.searchForm.pageSize = 10
      const query = this.searchForm
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 6)
      query.startDate = this.dateFormat(start)
      query.endDate = this.dateFormat(end)
      this.eJz4692(query)
    },
    eJz4692 (searchForm) {
      searchForm.userId = this.userId
      this.loading.list = true
      if (searchForm.dateArr) {
        delete searchForm.dateArr
      }
      this.api.system.account.eJz4692(searchForm).then(result => {
        const data = JSON.parse(result.data.data)
        console.log(11,data)
        if (data.code === '2000') {
          this.tableList = data || {}
        } else {
          this.tableList.itemList = []
          this.tableList.currentPage = '0'
          this.tableList.totalPage = '0'
          this.tableList.pageId = 0
          this.$message.warning(data.message)
        }
      }).finally(() => {
        this.loading.list = false
      })
    },
    search () {
      if (this.searchForm.dateArr) {
        this.searchForm.startDate = this.searchForm.dateArr[0]
        this.searchForm.endDate = this.searchForm.dateArr[1]
      }
      const searchForm = {
        pageIndex: 1,
        pageSize: 10,
        startDate: this.dateFormat(this.searchForm.startDate),
        endDate: this.dateFormat(this.searchForm.endDate)
      }
      this.eJz4692(searchForm)
    },
    reset () {
      this.searchForm.dateArr = []
      this.searchForm.startDate = ''
      this.searchForm.endDate = ''
      this.searchForm.pageIndex = 1
      this.searchForm.pageSize = 10
      this.getSearchForm()
    },
    changePageNum (val) {
      this.searchForm.pageIndex = val.pageNum
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 6)
      const searchForm = {
        pageIndex: this.searchForm.pageIndex,
        pageSize: this.searchForm.pageSize
      }
      if (this.searchForm.dateArr) {
        searchForm.startDate = this.dateFormat(this.searchForm.dateArr[0])
        searchForm.endDate = this.dateFormat(this.searchForm.dateArr[1])
      } else {
        searchForm.startDate = this.dateFormat(start)
        searchForm.endDate = this.dateFormat(end)
      }
      this.eJz4692(searchForm)
    },
    changeWhitePageNum (val) {
      this.whiteSearchForm.pageNum = val.pageNum
      this.getWhitePage()
    },
    getWhitePage () {
      this.whiteSearchForm.userId = this.userId
      this.whiteSearchForm.pageSize = 2
      this.api.system.account.getWhitePage(this.whiteSearchForm).then(result => {
        this.gridData = result.data.data
      }).catch(e => {})
    },
    filterMoney (num) {
      return (num / 100).toFixed(2)
    },
    dateFormat (date, r) {
      return date ? moment(date).format('YYYYMMDD') : (r || '-')
    },
    stringToDate (dateStr, separator){
      let str = dateStr.slice(0, 4) + '-' + dateStr.slice(4, 6) + '-' + dateStr.slice(6, 8) + ' ' + dateStr.slice(8, 10) + ':' + dateStr.slice(10, 12) + ':' + dateStr.slice(12, 14)
      return this.utils.dateTimeFormat(str)
    },
    clickRecharge () {
      if (['10', '15', '40'].includes(this.accountDetail.userType)) {
        this.dialog.singleRecharge.visible = true
        this.changeSingleRechargeMode()
      } else {
        this.changeRechargeMode()
        this.dialog.recharge.visible = true
      }
    },
    clickWithdrawal () {
      this.dialog.withdrawal.visible = true
    },
    clickTransfer () {
      if (['10', '15', '40'].includes(this.accountDetail.userType)) {
        this.dialog.singleTransfer.visible = true
        this.changeSingleTransferChujin()
      } else {
        this.dialog.transfer.visible = true
        this.changeTransferChujin()
      }
    },
    changeRechargeMode (val) {
      const data = this.rechargeForm.mode ? this.rechargeForm.mode : val
      if (this.validateFlag === 1) {
        this.$refs.rechargeForm.clearValidate()
      }
      this.appFormProcessing()
      switch (data) {
        case '13':
          this.rechargeRules.onLine.amount[0].required = true
          break
        case '16':
          this.rechargeRules.offline.bankAccountName[0].required = true
          this.rechargeRules.offline.bankAccountNumber[0].required = true
          break
        default:
          this.rechargeRules.onLine.amount[0].required = false
          this.rechargeRules.offline.bankAccountName[0].required = false
          this.rechargeRules.offline.bankAccountNumber[0].required = false
          break
      }
    },
    changeSingleRechargeMode (val) {
      const data = this.singleRechargeForm.mode ? this.singleRechargeForm.mode : val
      if (this.validateFlag === 1) {
        this.$refs.singleRechargeForm.clearValidate()
      }
      this.appFormProcessing()
      switch (data) {
        case '13':
          this.singleRechargeRules.onLine.amount[0].required = true
          this.singleRechargeRules.onLine.method[0].required = true
          break
        case '16':
          this.singleRechargeRules.offline.bankAccountName[0].required = true
          this.singleRechargeRules.offline.bankAccountNumber[0].required = true
          break
        default:
          this.singleRechargeRules.onLine.amount[0].required = false
          this.singleRechargeRules.onLine.method[0].required = false
          this.singleRechargeRules.offline.bankAccountName[0].required = false
          this.singleRechargeRules.offline.bankAccountNumber[0].required = false
          break
      }
    },
    changeTransferChujin (val) {
      const data = this.transferForm.isChujin ? this.transferForm.isChujin : val
      if (this.validateFlag === 1) {
        this.$refs.transferForm.clearValidate()
      }
      this.appFormProcessing()
      switch (data) {
        case '0':
          this.transferRules.internally.amount[0].required = true
          this.transferRules.internally.eAccountNumber[0].required = true
          break
        case '1':
          if (this.validateFlag !== 1 && !this.transferForm.internally.amount || !this.transferForm.internally.eAccountNumber) {
            this.$refs.transferForm.clearValidate()
          }
          this.getZjBankCodeList()
          this.transferRules.foreign.amount[0].required = true
          this.transferRules.foreign.bankAccountType[0].required = true
          this.transferRules.foreign.bankId[0].required = true
          this.transferRules.foreign.payeeAccountNumber[0].required = true
          this.transferRules.foreign.payeeAccountName[0].required = true
          this.transferRules.foreign.note[0].required = true
          break
        default:
          this.transferRules.internally.amount[0].required = false
          this.transferRules.internally.eAccountNumber[0].required = false
          this.transferRules.foreign.amount[0].required = false
          this.transferRules.foreign.bankAccountType[0].required = false
          this.transferRules.foreign.payeeAccountNumber[0].required = false
          this.transferRules.foreign.payeeAccountName[0].required = false
          this.transferRules.foreign.bankId[0].required = false
          this.transferRules.foreign.note[0].required = false
          break
      }
    },
    changeSingleTransferChujin (val) {
      const data = this.singleTransferForm.isChujin ? this.singleTransferForm.isChujin : val
      if (this.validateFlag === 1) {
        this.$refs.singleTransferForm.clearValidate()
      }
      this.appFormProcessing()
      switch (data) {
        case '0':
          this.singleTransferRules.internally.amount[0].required = true
          this.singleTransferRules.internally.eAccountNumber[0].required = true
          break
        case '1':
          if (this.validateFlag !== 1 && !this.singleTransferForm.internally.amount || !this.singleTransferForm.internally.eAccountNumber) {
            this.$refs.singleTransferForm.clearValidate()
          }
          this.getZjBankCodeList()
          this.singleTransferRules.foreign.amount[0].required = true
          this.singleTransferRules.foreign.bankAccountType[0].required = true
          this.singleTransferRules.foreign.bankId[0].required = true
          this.singleTransferRules.foreign.payeeAccountNumber[0].required = true
          this.singleTransferRules.foreign.payeeAccountName[0].required = true
          this.singleTransferRules.foreign.note[0].required = true
          break
        default:
          this.singleTransferRules.internally.amount[0].required = false
          this.singleTransferRules.internally.eAccountNumber[0].required = false
          this.singleTransferRules.foreign.amount[0].required = false
          this.singleTransferRules.foreign.bankAccountType[0].required = false
          this.singleTransferRules.foreign.bankId[0].required = false
          this.singleTransferRules.foreign.payeeAccountNumber[0].required = false
          this.singleTransferRules.foreign.payeeAccountName[0].required = false
          this.singleTransferRules.foreign.note[0].required = false
          break
      }
    },
    appFormProcessing () {
      this.rechargeForm.offline.bankAccountName = ''
      this.rechargeForm.offline.bankAccountNumber = ''
      this.rechargeForm.onLine.amount = ''
      this.singleRechargeForm.onLine.amount = ''
      this.singleRechargeForm.onLine.method = ''
      this.singleRechargeForm.onLine.paymentWay = ''
      this.singleRechargeForm.onLine.QRPaymentWay = ''
      this.singleRechargeForm.offline.bankAccountName = ''
      this.singleRechargeForm.offline.bankAccountNumber = ''
      this.withdrawalForm.amount = ''
      this.transferForm.internally.amount = ''
      this.transferForm.internally.eAccountNumber = ''
      this.transferForm.foreign.amount = ''
      this.transferForm.foreign.payeeAccountNumber = ''
      this.transferForm.foreign.payeeAccountName = ''
      this.transferForm.foreign.bankAccountType = ''
      this.transferForm.foreign.bankId = ''
      this.transferForm.foreign.note = ''
      this.singleTransferForm.internally.amount = ''
      this.singleTransferForm.internally.eAccountNumber = ''
      this.singleTransferForm.foreign.amount = ''
      this.singleTransferForm.foreign.payeeAccountNumber = ''
      this.singleTransferForm.foreign.payeeAccountName = ''
      this.singleTransferForm.foreign.bankAccountType = ''
      this.singleTransferForm.foreign.bankId = ''
      this.singleTransferForm.foreign.note = ''
    },
    getZjBankCodeList () {
      this.api.system.account.zjBankCodeList().then(result => {
        this.zjBankCodeList = result.data.data || []
      }).finally(() => {
      })
    },
    submitRechargeForm () {
      this.$refs.rechargeForm.validate((valid) => {
        if (valid) {
          if (this.rechargeForm.mode === '13') {
            this.loading.submit = true
            const data = this._.cloneDeep(this.rechargeForm.onLine)
            data.userId = this.userId
            this.api.system.account.eJz4641(data.userId, data.amount, data.paymentWay, '', 'www.baidu.com').then(result => {
              const data = JSON.parse(result.data.data)
              if (data.code === '2000') {
                this.dialog.recharge.visible = false
                this.$message.success('操作成功')
                console.log(data.eBankPayURL)
                window.open(data.eBankPayURL, '_blank')
                this.loading.submit = false
                this.closeDialog()
              } else {
                this.loading.submit = false
                this.$message.error(data.message)
              }
            }).catch(e => {
              this.loading.submit = false
            })
          } else {
            this.loading.submit = true
            const data = this._.cloneDeep(this.rechargeForm.offline)
            data.payeeUserID = this.userId
            this.api.system.account.eJz4665(data.payeeUserID, data.bankAccountName, data.bankAccountNumber).then(result => {
              const data = JSON.parse(result.data.data)
              if (data.code === '2000') {
                this.dialog.recharge.visible = false
                this.$message.success('操作成功')
                this.closeDialog()
                this.loading.submit = false
              } else {
                this.loading.submit = false
                this.$message.error(data.message)
              }
            }).catch(e => {
              this.loading.submit = false
            })
          }
        } else {
          this.validateFlag = 1
        }
      })
    },
    submitSingleRechargeForm () {
      this.$refs.singleRechargeForm.validate((valid) => {
        if (valid) {
          if (this.singleRechargeForm.mode === '13') {
            this.loading.submit = true
            const data = this._.cloneDeep(this.singleRechargeForm.onLine)
            if (data.method === 'kj') {
              data.paymentWay = '10'
            } else if (data.method === 'wx') {
              data.paymentWay = '40'
              data.QRPaymentWay = '10'
            } else if (data.method === 'zfb') {
              data.paymentWay = '40'
              data.QRPaymentWay = '20'
            } else {
              data.paymentWay = ''
              data.QRPaymentWay = ''
            }
            data.userId = this.userId
            this.api.system.account.eJz4641(data.userId, data.amount, data.paymentWay, data.QRPaymentWay, 'www.baidu.com').then(result => {
              const data = JSON.parse(result.data.data)
              if (data.code === '2000') {
                this.dialog.singleRecharge.visible = false
                this.$message.success('操作成功')
                if (this.singleRechargeForm.onLine.method === 'kj') {
                  this.loading.submit = false
                  this.closeDialog()
                } else {
                  console.log('zfb、wx：' + data.qRImageUR)
                  window.open(data.qRImageURL, '_blank')
                  this.loading.submit = false
                  this.closeDialog()
                }
              } else {
                this.loading.submit = false
                this.$message.error(data.message)
              }
            }).catch(e => {
              this.loading.submit = false
            })
          } else {
            this.loading.submit = true
            const data = this._.cloneDeep(this.singleRechargeForm.offline)
            data.payeeUserID = this.userId
            this.api.system.account.eJz4665(data.payeeUserID, data.bankAccountName, data.bankAccountNumber).then(result => {
              const data = JSON.parse(result.data.data)
              if (data.code === '2000') {
                this.dialog.singleRecharge.visible = false
                this.$message.success('操作成功')
                this.closeDialog()
                this.loading.submit = false
              } else {
                this.loading.submit = false
                this.$message.error(data.message)
              }
            }).catch(e => {
              this.loading.submit = false
            })
          }
        } else {
          this.validateFlag = 1
        }
      })
    },
    submitWithdrawalForm () {
      this.$refs.withdrawalForm.validate().then(() => {
        this.loading.submit = true
        this.api.system.account.eJz4643(this.userId, this.withdrawalForm.amount).then(result => {
          const data = JSON.parse(result.data.data)
          if (data.code === '2000') {
            this.dialog.withdrawal.visible = false
            this.$message.success('操作成功')
            this.closeDialog()
            this.loading.submit = false
          } else {
            this.loading.submit = false
            this.$message.error(data.message)
          }
        }).catch(e => {
          this.loading.submit = false
        })
      })
    },
    submitTransferForm () {
      this.$refs.transferForm.validate((valid) => {
        if (valid) {
          if (this.transferForm.isChujin === '0') {
            this.loading.submit = true
            const data = this._.cloneDeep(this.transferForm.internally)
            const info = {
              isChujin: this.transferForm.isChujin,
              userId: this.userId,
              amount: data.amount,
              eAccountNumber: data.eAccountNumber,
              pageURL: 'www.baidu.com'
            }
            this.api.system.account.eJz5011(info).then(result => {
              const data = JSON.parse(result.data.data)
              if (data.code === '2000') {
                this.dialog.transfer.visible = false
                this.$message.success('操作成功')
                this.closeDialog()
                this.loading.submit = false
              } else {
                this.loading.submit = false
                this.$message.error(data.message)
              }
            })
          } else {
            this.loading.submit = true
            const data = this._.cloneDeep(this.transferForm.foreign)
            const info = {
              isChujin: this.transferForm.isChujin,
              userId: this.userId,
              amount: data.amount,
              bankAccountType: data.bankAccountType,
              payeeAccountNumber: data.payeeAccountNumber,
              payeeAccountName: data.payeeAccountName,
              note: data.note,
              bankId: data.bankId,
              pageURL: 'www.baidu.com'
            }
            this.api.system.account.eJz5011(info).then(result => {
              const data = JSON.parse(result.data.data)
              if (data.code === '2000') {
                this.dialog.transfer.visible = false
                this.$message.success('操作成功')
                this.closeDialog()
                this.loading.submit = false
              } else {
                this.loading.submit = false
                this.$message.error(data.message)
              }
            }).catch(e => {
              this.loading.submit = false
            })
          }
        } else {
          this.validateFlag = 1
        }
      })
    },
    submitSingleTransferForm () {
      this.$refs.singleTransferForm.validate((valid) => {
        if (valid) {
          if (this.singleTransferForm.isChujin === '0') {
            this.loading.submit = true
            const data = this._.cloneDeep(this.singleTransferForm.internally)
            const info = {
              isChujin: this.singleTransferForm.isChujin,
              userId: this.userId,
              amount: data.amount,
              eAccountNumber: data.eAccountNumber,
              pageURL: 'www.baidu.com'
            }
            this.api.system.account.eJz5011(info).then(result => {
              const data = JSON.parse(result.data.data)
              if (data.code === '2000') {
                this.dialog.singleTransfer.visible = false
                this.$message.success('操作成功')
                this.closeDialog()
                this.loading.submit = false
              } else {
                this.loading.submit = false
                this.$message.error(data.message)
              }
            }).catch(e => {
              this.loading.submit = false
            })
          } else {
            this.loading.submit = true
            const data = this._.cloneDeep(this.singleTransferForm.foreign)
            const info = {
              isChujin: this.singleTransferForm.isChujin,
              userId: this.userId,
              amount: data.amount,
              bankAccountType: data.bankAccountType,
              payeeAccountNumber: data.payeeAccountNumber,
              payeeAccountName: data.payeeAccountName,
              note: data.note,
              bankId: data.bankId,
              pageURL: 'www.baidu.com'
            }
            this.api.system.account.eJz5011(info).then(result => {
              const data = JSON.parse(result.data.data)
              if (data.code === '2000') {
                this.dialog.singleTransfer.visible = false
                this.$message.success('操作成功')
                this.closeDialog()
                this.loading.submit = false
              } else {
                this.loading.submit = false
                this.$message.error(data.message)
              }
            }).catch(e => {
              this.loading.submit = false
            })
          }
        } else {
          this.validateFlag = 1
        }
      })
    },
    closeDialog () {
      this.rechargeForm.mode = '13'
      this.singleRechargeForm.mode = '13'
      this.transferForm.isChujin = '0'
      this.appFormProcessing()
      this.eJz4691()
      this.recoverSearch()
    }
  }
}
</script>

<style lang="less" scoped>
.area {
  overflow: hidden;
  line-height: 30px;
  height: 40px;
  padding: 0 20px;
  margin-bottom: 20px;
  .title {
    float: left;
    margin-top: 5px;
    line-height: 32px;
    font-size: 14px;
    color: #666;
  }
  .btn-inner {
    float: right;
    margin-top: 5px;
  }
}
div.radio input{margin-left:30px}
</style>
